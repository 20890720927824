<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-2xl py-2">
      <a href="/billing/dashboard" class="text-sm font-semibold leading-6 text-gray-900">Dashboard <span aria-hidden="true">→</span></a>
    </div>

    <div>
    <button @click="connectToXero" class="btn btn-primary  bg-blue-100 text-blue-600 ring-blue-600 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset">Connect to Xero</button>
    <div v-if="isConnected" class="mt-2 bg-green-100 text-green-600 ring-green-600 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset">Connected to Xero</div>
    </div>

    <div class="sm:flex sm:items-center">

    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

          <table>
          <thead>
            <tr class="">
              <th class="text-left">Family Code</th>
              <th class="text-left">Student Name</th>
              <th class="text-left">Calculation</th>
              <th class="text-left w-32">Term 1</th>
              <th class="text-left w-32">Term 2</th>
              <th class="text-left w-32">Term 3</th>
              <th class="text-left w-32">Term 4</th>
            </tr>
          </thead>
            <!-- Use a new <tbody> for each family -->
            <tbody v-for="family in families" :key="family.code">
              <tr v-for="(student, index) in family.students" :key="`${family.code}-${student._id}`">
                <td v-if="index === 0" :rowspan="family.students.length + 2" class="align-top text-left font-bold">{{ family.code }}</td>
                <td class="text-blue-500 text-left w-40 truncate underline cursor-pointer" @click="openDialog(student)">[{{ student.heroId }}] {{ student.firstNameLegal }} {{ student.lastNameLegal }} - {{ student.currentYear }}</td>
                <!-- Adjust the termFees rendering as per your data structure -->
                <td class="text-xs text-left">
                  <span :class="{
                'bg-purple-50 text-purple-700 ring-purple-600 text-center w-30': status(student.debenturesCorp, student.staffChild, student.bursary) === 'Partners in Education',
                'bg-yellow-50 text-yellow-700 ring-yellow-600 text-center w-30': status(student.debenturesCorp, student.staffChild, student.bursary) === 'Educators\' Family',
                'bg-yellow-50 text-orange-700 ring-orange-600 text-center w-16': status(student.debenturesCorp, student.staffChild, student.bursary) === 'Bursary',
                'bg-green-50 text-green-700 ring-green-600 text-center w-16': status(student.debenturesCorp, student.staffChild, student.bursary, student.firstAttendanceDate) === 'Regular',
                'bg-blue-50 text-blue-700 ring-blue-600 text-center w-20': status(student.debenturesCorp, student.staffChild, student.bursary, student.firstAttendanceDate) === 'Long Term',
              }" class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset">
              {{ status(student.debenturesCorp, student.staffChild, student.bursary, student.firstAttendanceDate) }}
            </span>
            Base Fee {{ calculateFee(student.currentYear, student.feeComponent) }} Levy {{ ressourceLevy(student.currentYear) }}, <span class="bg-blue-100 text-blue-800 ring-blue-600 text-center">{{ student.feeComponent }}</span><span class="bg-yellow-50 text-orange-700 ring-orange-600 text-center w-16"> Discount {{ calculateDiscount(student).discountType }}</span>
            <p>Term 2 Subsidy - {{ student.subsidy24term2 || 'False' }}</p>
</td>
                <td class="text-left px-2">{{ student.totals?.term1 }} </td>
                <td class="text-left px-2">{{ student.totals?.term2 }}</td>
                <td class="text-left px-2">{{ student.totals?.term3 }}</td>
                <td class="text-left px-2">{{ student.totals?.term4 }}</td>
              </tr>
              <!-- Total row for the family -->
              <tr>
                <td></td>
                <td class="text-right font-bold"><span class="pr-4">Total</span>  </td>
                <td class="font-bold bg-gray-300 text-left px-2" v-for="(total, totalIndex) in family.termTotals" :key="`${family.code}-total-${totalIndex}`">{{ total }} 
                </td>
              </tr>
                <!-- Row for displaying invoices aligned with term totals -->
  <tr class="border-b-2 border-gray-300">
    <td colspan="2"></td>
 <!-- Row for displaying invoices aligned with term totals -->
    <!-- Term 1 Invoice Button -->
    <td class="text-left w-32">
      <a v-if="family.invoices && family.invoices['Term12024Invoice']" :href="`https://go.xero.com/app/!z2WTp/invoicing/edit/${family.invoices['Term12024Invoice']}`" target="_blank" class="whitespace-nowrap my-2 btn px-2 py-1 text-xs bg-green-100 text-green-600 ring-green-600 inline-flex items-center rounded-md ring-1 ring-inset hover:bg-green-200">
        View Invoice
      </a>
      <button v-else @click="generateInvoiceForFamily(family.code, 1)" class="my-2 btn px-2 py-1 text-xs bg-blue-100 text-blue-600 ring-blue-600 inline-flex items-center rounded-md ring-1 ring-inset hover:bg-blue-200">
        Generate Invoice
      </button>
    </td>
    <!-- Term 2 Invoice Button -->
    <td class="text-left w-32">
      <a v-if="family.invoices && family.invoices['Term22024Invoice']" :href="`https://go.xero.com/app/!z2WTp/invoicing/edit/${family.invoices['Term22024Invoice']}`" target="_blank" class="whitespace-nowrap my-2 btn px-2 py-1 text-xs bg-green-100 text-green-600 ring-green-600 inline-flex items-center rounded-md ring-1 ring-inset hover:bg-green-200">
        View Invoice
      </a>
      <button v-else @click="generateInvoiceForFamily(family.code, 2)" class="my-2 btn px-2 py-1 text-xs bg-blue-100 text-blue-600 ring-blue-600 inline-flex items-center rounded-md ring-1 ring-inset hover:bg-blue-200">
        Generate Invoice
      </button>
    </td>
    <!-- Term 3 Invoice Button -->
    <td class="text-left w-32">
      <a v-if="family.invoices && family.invoices['Term32024Invoice']" :href="`https://go.xero.com/app/!z2WTp/invoicing/edit/${family.invoices['Term32024Invoice']}`" target="_blank" class="whitespace-nowrap my-2 btn px-2 py-1 text-xs bg-green-100 text-green-600 ring-green-600 inline-flex items-center rounded-md ring-1 ring-inset hover:bg-green-200">
        View Invoice
      </a>
      <button v-else @click="generateInvoiceForFamily(family.code, 3)" class="my-2 btn px-2 py-1 text-xs bg-blue-100 text-blue-600 ring-blue-600 inline-flex items-center rounded-md ring-1 ring-inset hover:bg-blue-200">
        Generate Invoice
      </button>
    </td>
    <!-- Term 4 Invoice Button -->
    <td class="text-left w-32">
      <a v-if="family.invoices && family.invoices['Term42024Invoice']" :href="`https://go.xero.com/app/!z2WTp/invoicing/edit/${family.invoices['Term42024Invoice']}`" target="_blank" class="whitespace-nowrap my-2 btn px-2 py-1 text-xs bg-green-100 text-green-600 ring-green-600 inline-flex items-center rounded-md ring-1 ring-inset hover:bg-green-200">
        View Invoice
      </a>
      <button v-else @click="generateInvoiceForFamily(family.code, 4)" class="my-2 btn px-2 py-1 text-xs bg-blue-100 text-blue-600 ring-blue-600 inline-flex items-center rounded-md ring-1 ring-inset hover:bg-blue-200">
        Generate Invoice
      </button>
    </td>
    
    <td><!-- Placeholder if you have an extra column for "Total" or similar --></td>
  </tr>
  <!-- Placeholder row for alignment, if needed -->
  <tr>

              </tr>
            </tbody>
        </table>



        </div>
      </div>
    </div>
  </div>


<!-- MODAL EDIT STUDENT -->
<TransitionRoot as="template" :show="isOpen">
  <Dialog as="div" class="relative z-10" :open="isOpen" @close="isOpen = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle v-if="selectedStudent" class="text-base font-semibold leading-6 text-gray-900">{{ selectedStudent.firstNameLegal }} {{ selectedStudent.lastNameLegal }}</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="isOpen = false">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <!-- Your content -->
                    <template v-if="selectedStudent">
                      <form @submit.prevent="updateStudent">
                        <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label class="block text-sm font-medium text-gray-700">
                        First Name
                        <input v-model="selectedStudent.firstNameLegal" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Middle Names
                        <input v-model="selectedStudent.middleNames" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Last Name
                        <input v-model="selectedStudent.lastNameLegal" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Hero ID
                        <input v-model="selectedStudent.heroId" type="number" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        First Attendance Date
                        <input v-model="formattedFirstAttendanceDate" type="date" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Current Year
                        <input v-model="selectedStudent.currentYear" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label class="block text-sm font-medium text-gray-700">
                          Fee Component
                          <select v-model="selectedStudent.feeComponent" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <option value="full5days">Full ELP Program (5days)</option>
                            <option value="mornings5days">All Mornings (5days)</option>
                            <option value="mornings3days">Three Mornings (3days)</option>
                            <option value="littleExplorer3days">Little Explorers (3days)</option>
                            <option value="SS1">Sace Stage 1</option>
                            <option value="SS2">Sace Stage 2</option>
                          </select>
                        </label>
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label class="block text-sm font-medium text-gray-700">
                          Status
                          <div class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <div>
                              <input type="checkbox" v-model="selectedStudent.debenturesCorp" id="debenturesCorp" />
                              <label for="debenturesCorp" class="ml-2">Debentures or Corporate</label>
                            </div>
                            <div>
                              <input type="checkbox" v-model="selectedStudent.staffChild" id="staffChild" />
                              <label for="staffChild" class="ml-2">Child of Staff</label>
                            </div>
                            <div>
                              <input type="checkbox" v-model="selectedStudent.bursary" id="bursary" />
                              <label for="bursary" class="ml-2">Bursary</label>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Family Code
                        <input v-model="selectedStudent.familyCode" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Primary Citizenship
                        <input v-model="selectedStudent.primaryCitizenship" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 1 First Name
                        <input v-model="selectedStudent.contact1FirstName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 1 Last Name
                        <input v-model="selectedStudent.contact1LastName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 1 Email
                        <input v-model="selectedStudent.contact1Email" type="email" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 2 First Name
                        <input v-model="selectedStudent.contact2FirstName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 2 Last Name
                        <input v-model="selectedStudent.contact2LastName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 2 Email
                        <input v-model="selectedStudent.contact2Email" type="email" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      </div>
                      
                    </form>
                  </template>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button" class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" @click="closeDialog">Cancel</button>
<button type="submit" class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500" @click="saveData">Save</button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script setup>
import axios from 'axios';
import { onMounted, ref, defineExpose, reactive, computed } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const isBulkLoading = ref(false);
let isLoading = reactive({});
let settings = ref(null);
let isOpen = ref(false);
let selectedStudent = ref(null);

const openDialog = (student) => {
  selectedStudent.value = student;
  isOpen.value = true;
};

const saveData = async () => {
  try {
    await axios.put('/api/updateStudent', {
      id: selectedStudent.value._id,
      studentData: selectedStudent.value
    });
    isOpen.value = false; // Close the slide-over panel after saving
  } catch (error) {
    console.error('Error updating student:', error);
  }
};

const closeDialog = () => {
  isOpen.value = false; // Close the slide-over panel
};


// Function to format date
const formatDateToYYYYMMDD = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

// Computed property for formatted date
const formattedFirstAttendanceDate = computed({
  get: () => formatDateToYYYYMMDD(selectedStudent.value.firstAttendanceDate),
  set: (value) => { selectedStudent.value.firstAttendanceDate = new Date(value); }
});


const students = ref([]);
const invoices = ref([]);

const studentsData = ref([]); // Placeholder for fetched student data
const families = ref([]);

const fetchStudentsAndOrganizeByFamily = async () => {
  try {
    console.log("Fetching students...");
    const response = await axios.get('/api/getStudents');
    //console.log("Students fetched successfully:", response.data);
    studentsData.value = response.data;
    organizeStudentsByFamily();
  } catch (error) {
    console.error('Error fetching students:', error);
  }
};

const organizeStudentsByFamily = () => {
  console.log("Organizing students by family...");
  const familyMap = new Map();

  if (!studentsData.value || studentsData.value.length === 0) {
    return;
  }

  studentsData.value.forEach(student => {
    const { familyCode } = student;

    if (!student.totals) {
      student.totals = calculateTotalForAllTerms(student);
    }

    if (!familyMap.has(familyCode)) {
      familyMap.set(familyCode, {
        code: familyCode,
        students: [],
        termTotals: { term1: 0, term2: 0, term3: 0, term4: 0 },
        grandTotal: 0,
        invoices: {} // Initialize invoices as an empty object
      });
    }
    const family = familyMap.get(familyCode);
    family.students.push(student);

    // Ensure student.Invoices is defined before attempting to access its properties
    if (student.Invoices) {
      ['Term12024Invoice', 'Term22024Invoice', 'Term32024Invoice', 'Term42024Invoice'].forEach(termInvoice => {
        if (student.Invoices[termInvoice] && !family.invoices[termInvoice]) {
          // Assign the invoice ID to the corresponding term in the family's invoices object
          family.invoices[termInvoice] = student.Invoices[termInvoice];
          // Log when an invoice is found and associated with a family
          //console.log(`Invoice found for familyCode: ${familyCode}, ${termInvoice}: ${student.Invoices[termInvoice]}`);
        }
      });
    }

    // Calculate term totals and grand total for the family
    Object.keys(student.totals).forEach(term => {
      family.termTotals[term] += student.totals[term];
      family.grandTotal += student.totals[term];
    });
  });

  families.value = Array.from(familyMap.values());
  //console.log("Families organized:", families.value);
};

const fetchSettings = async () => {
  try {
    const response = await axios.get('/api/getSettings');
    settings.value = response.data;
  } catch (error) {
    console.error('Error fetching settings:', error);
  }
};

const clientId = process.env.VUE_APP_XERO_CLIENTID; // Replace with your actual client ID
const redirectUri = 'https://pvis.edu.vu/api/auth'; // Replace with your actual redirect URI
const scope = 'openid profile email accounting.contacts accounting.transactions accounting.settings accounting.attachments'; // Replace with the scopes you need
let state = '123'; // Replace with your actual state
const isConnected = ref(false); // Add this line

const connectToXero = () => {
  const authUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
  
  console.log(authUrl); // Log the URL

  window.location.href = authUrl; // Open the URL in a new window or tab

};

onMounted(async () => {
  await fetchSettings();
  await fetchStudentsAndOrganizeByFamily(); // Ensure this is awaited if it's asynchronous

  // Assuming families is a reactive property containing the organized families and students
  families.value.forEach(family => {
    family.students.forEach(student => {
      //console.log('Calculating totals for student:', student);
      // Directly update the student's totals property with the calculated totals
      // This assumes student objects are already reactive
      student.totals = calculateTotalForAllTerms(student);
    });
  });

  // Additional logic as needed...
});

const calculateCompletedYears = (firstAttendanceDate) => {
  const startDate = new Date(firstAttendanceDate);
  const endDate = new Date();

  if (isNaN(startDate.getTime())) {
    return ''; // return an empty string if firstAttendanceDate is not a valid date
  }

  let years = endDate.getFullYear() - startDate.getFullYear();

  // Subtract a year if the current date is before the attendance date in the current year
  if (endDate.getMonth() < startDate.getMonth() || 
      (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())) {
    years--;
  }

  return years < 0 ? 0 : years; // returns the number of fully completed years, or 0 if the first attendance date is in the future
};

const TermFee = (level) => {
  const fees = {
    'ELP': 149427,
    'PRE': 149427,
    'FND': 208472,
    'Year 1': 208472,
    'Year 2': 208472,
    'Year 3': 208472,
    'Year 4': 208472,
    'Year 5': 208472,
    'Year 6': 208472,
    'Year 7': 225982,
    'Year 8': 225982,
    'Year 9': 225982,
    'Year 10': 225982,
    'Year 11': 230411,
    'Year 12': 230411,
  };

  return fees[level] || 0;
};

const ressourceLevy = (level) => {
  const fees = {
    'ELP': 21500,
    'PRE': 21500,
    'FND': 22500,
    'Year 1': 22500,
    'Year 2': 22500,
    'Year 3': 22500,
    'Year 4': 22500,
    'Year 5': 22500,
    'Year 6': 22500,
    'Year 7': 27500,
    'Year 8': 27500,
    'Year 9': 27500,
    'Year 10': 27500,
    'Year 11': 42500,
    'Year 12': 52500,
  };

  return fees[level] || 0;
};

const feeComponents = {
  'full5days': 149427,
  'mornings5days': 105987,
  'mornings5daysLE': 136887,
  'mornings3days': 78589,
  'littleExplorer3days': 109489,
  'SS1': 96705,
  'SS2': 159600
};

const calculateFee = (level, feeComponent) => {
  // If the feeComponent is one of the specific components, return its value directly, replacing the base fee.
  if (['full5days', 'mornings5days', 'mornings5daysLE', 'mornings3days', 'littleExplorer3days'].includes(feeComponent)) {
    return feeComponents[feeComponent] || 0;
  } else {
    // For other cases, return the base fee determined by the level.
    return TermFee(level);
  }
};

const status = (debenturesCorp, staffChild, bursary, firstAttendanceDate) => {
  const years = calculateCompletedYears(firstAttendanceDate);

  if (debenturesCorp) {
    return 'Partners in Education';
  } else if (staffChild) {
    return 'Educators\' Family';
  } else if (bursary) {
    return 'Bursary';
  } else if (years > 2) {
    return 'Long Term';
  } else {
    return 'Regular';
  }
};

const calculateDiscount = (student) => {
  let discountName = '';
  let discountValue = 0;
  let discountType = 'None'; // Default to 'None'

  // Calculate the number of completed years to determine if the student is long-term
  const years = calculateCompletedYears(student.firstAttendanceDate);

  if (student.currentYear === 'ELP' || student.currentYear === 'PRE') {
    // Early return for ELP or PRE with 'None' as the discountType
    return { discountName, discountValue, discountType };
  }

  if (student.debenturesCorp) {
    discountName = 'Partners in Education';
    discountType = 'Partners'; // Assuming a specific type for Partners in Education
  } else if (years > 2 || student.primaryCitizenship === 'Vanuatu' || student.staffChild) {
    discountName = 'Discount B';
    discountValue = 60000;
    discountType = 'B';
  } else {
    // Apply Discount A if none of the above conditions are met
    discountName = 'Discount A';
    discountValue = 33000;
    discountType = 'A';
  }

  return { discountName, discountValue, discountType };
};

const calculateTotalForAllTerms = (student) => {
  if (!student || typeof student.currentYear === 'undefined') {
    console.error('Invalid or incomplete student object provided:', student);
    return { term1: 0, term2: 0, term3: 0, term4: 0 };
  }
  const totals = {};

  for (let term = 1; term <= 4; term++) {
    let fee = calculateFee(student.currentYear, student.feeComponent);
    const { discountValue } = calculateDiscount(student);

    let total = fee - discountValue;

    if (student.bursary || student.staffChild) {
      total *= 0.5; // Apply additional discount
    }

    if (term === 1) {
      total += ressourceLevy(student.currentYear); // Add resource levy only for term 1
      
      // For 'SS1' and 'SS2', add their feeComponent value to the total only for term 1
      if (['SS1', 'SS2'].includes(student.feeComponent)) {
        total += feeComponents[student.feeComponent] || 0;
      }
    }

    totals[`term${term}`] = total;
  }

  return totals;
};

const generateInvoiceForFamily = async (familyCode, term) => {
  console.log(`Generating invoice for term: ${term}, family code: ${familyCode}`); // Log the term and family code
  isLoading[familyCode] = true;

  // Define studentsInFamily at a higher scope level, initializing it as an empty array
  let studentsInFamily = [];

  // Assuming families.value is an array of family objects
  const family = families.value.find(f => f.code === familyCode);

  if (family) {
    studentsInFamily = family.students; // Assign the students from the found family
    console.log(`Students in family ${familyCode}:`, studentsInFamily);
  } else {
    console.log(`No family found with code ${familyCode}`);
  }

  // Ensure termIndex is within the bounds of the invoiceDates array
  const termIndex = Math.max(0, Math.min(term - 1, settings.value.invoiceDates.length - 1));
  const invoiceDateInput = settings.value.invoiceDates[termIndex];
  const paymentTermDays = Number(settings.value.paymentTerm) || 30; // Default to 30 days if undefined or invalid

    // Validate invoiceDateInput before constructing the Date object
    const invoiceDate = invoiceDateInput ? new Date(invoiceDateInput) : new Date();
  if (isNaN(invoiceDate.getTime())) {
    console.error('Invalid invoice date:', invoiceDateInput);
    isLoading[familyCode] = false;
    return; // Exit the function if the invoice date is invalid
  }
  // Calculate due date based on settings
  const dueDate = new Date(invoiceDate);
  dueDate.setDate(dueDate.getDate() + paymentTermDays);

  // Format dates to YYYY-MM-DD for consistency
  const invoiceDateString = invoiceDate.toISOString().split('T')[0];
  const dueDateString = dueDate.toISOString().split('T')[0];

  
  // Prepare line items for each student in the family
  const lineItems = studentsInFamily.map(student => {
    // Prepare the data for the invoice
    const email1 = student.contact1Email;
    const firstName1 = student.contact1FirstName;
    const lastName1 = student.contact1LastName;
    const email2 = student.contact2Email;
    const firstName2 = student.contact2FirstName;
    const lastName2 = student.contact2LastName;
    const currentYear = student.currentYear;
    const { discountName: discountName, discountValue: discountValue } = calculateDiscount(student);
    const heroID = student.heroId;
    const familyID = student.familyCode;
    const studentFirstName = student.firstNameLegal;
    const studentLastName = student.lastNameLegal;
    const status = student.status;
    
    // Recalculate the fee if feeComponent is one of the first five options
    let fee = calculateFee(student.currentYear, student.feeComponent);
    if (['full5days', 'mornings5days', 'mornings5daysLE', 'mornings3days', 'littleExplorer3days'].includes(student.feeComponent)) {
      fee = feeComponents[student.feeComponent] || 0;
    }

     // Calculate ressourceLevy, sace, and otherDiscount
     const resLevy = ressourceLevy(student.currentYear);
      let sace = 0;
      let saceName = '';
      if (term === 1 && ['SS1', 'SS2'].includes(student.feeComponent)) {
        sace = feeComponents[student.feeComponent] || 0;
        saceName = student.feeComponent === 'SS1' ? 'Sace Stage 1 Fee' : 'Sace Stage 2 Fee';
      }
    let otherDiscount = 0;
    let otherDiscountName = '';
    let otherDiscountAccountCode = '';
    if (student.bursary) {
      otherDiscount = (fee - discountValue) * 0.5;
      otherDiscountName = 'Bursary';
      otherDiscountAccountCode = '4-1320';
    } else if (student.staffChild) {
      otherDiscount = (fee - discountValue) * 0.5;
      otherDiscountName = 'Staff Discount';
      otherDiscountAccountCode = '4-1230';
    }


    // Return line item
    return {
      email1,
      firstName1,
      lastName1,
      email2,
      firstName2,
      lastName2,
      fee,
      heroID,
      familyID,
      currentYear,
      discountName,
      discountValue,
      studentFirstName,
      studentLastName,
      status,
      resLevy,
      sace,
      saceName,
      otherDiscount,
      otherDiscountName,
      otherDiscountAccountCode,
      subsidy24term2: student.subsidy24term2 || false, // Ensure a value is always sent, defaulting to false
    };
  });
  console.log("Line items prepared for invoice:", lineItems);
  console.log("Students in family:", studentsInFamily);


  // Calculate total invoice amount
  const totalInvoiceAmount = lineItems.reduce((total, item) => total + item.fee, 0);

  // Extract all heroIDs from the lineItems
  const heroIDs = lineItems.map(item => item.heroID).filter(Boolean);

  // Create a string that concatenates all the heroIDs with a separator
  const reference = heroIDs.join(', ');

  // Generate the invoice
  try {
    const response = await axios.post('/api/generateFamilyInvoice', {
      lineItems,
      totalInvoiceAmount,
      numStudents: studentsInFamily.length,
      reference,
      invoiceDateString,
      dueDateString,
      term, // Include term in the API call if needed
    });

    const invoiceId = response.data.invoiceId;
    console.log('Invoice generated:', invoiceId);

    // Store the invoiceId in your MongoDB database
    await axios.post('/api/storeInvoiceId', { familyID: familyCode, invoiceId, term });

    // Add the newly generated invoice to the invoices array
    invoices.value.push({ familyCode, invoiceId });

    // Update the student's Invoices object with the new invoice ID for the specific term
    students.value.forEach(student => {
      if (student.familyCode === familyCode) {
        if (!student.Invoices) {
          student.Invoices = {};
        }
        // Dynamically set the invoice ID based on the term
        student.Invoices[`Term${term}2024Invoice`] = invoiceId;
      }
    });
    // Find the family object directly in the families array
const family = families.value.find(f => f.code === familyCode);

// Directly update the invoices object for the specific term
if (family && family.invoices) {
  family.invoices[`Term${term}2024Invoice`] = invoiceId;
} else if (family && !family.invoices) {
  // If the invoices object doesn't exist, initialize it
  family.invoices = { [`Term${term}2024Invoice`]: invoiceId };
}

  } catch (error) {
    console.error('Error generating invoice:', error);
  }
  isLoading[familyCode] = false;
};


// Function to generate invoices in bulk
const generateInvoicesInBulk = async () => {
  isBulkLoading.value = true;
  // Get unique family codes
  const familyCodes = [...new Set(students.value.map(student => student.familyCode))];

  // Generate invoices for each family
  for (let i = 0; i < familyCodes.length; i++) {
    // Regenerate the token every 50 invoices
    if (i % 50 === 0) {
      await regenerateToken();
    }

    try {
      await generateInvoiceForFamily(familyCodes[i]);
    } catch (error) {
      console.error('Error generating invoice for family:', familyCodes[i], error);
      // Continue with the next iteration even if an error occurs
    }
  }
  isBulkLoading.value = false;
};

const regenerateToken = async () => {
  try {
    const response = await axios.get('/api/refreshToken');
    console.log(response.data.message); // Log the success message
  } catch (error) {
    console.error('Error regenerating token:', error);
    // Handle the error, e.g., by showing a notification to the user
  }
};

defineExpose({
  status,
  generateInvoiceForFamily,
  generateInvoicesInBulk,
  isBulkLoading
});

</script>

<style scoped>
.bold-font {
  font-weight: bold;
}
</style>



<span v-else class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"></span>